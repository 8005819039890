.printshop-display-flex-content {
    display: flex;
    margin-left: 95px;
    width: 110px;
    align-items: center;
}

.printshop-toggle {
    width: 51px;
    margin: 0px auto;
}