.home-humility{
  text-align: center;
}

.home-fa-twitter{
  margin-left: 2em;
}

.subTitle{
  margin-top: 25px;
  margin-bottom: 25px;
}