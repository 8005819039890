.app p,
.app table,
.app td,
.app label,
.app form,
.app select,
.app hr,
.app option,
.app,
.body,
.app th{
    font-size: 12px !important;
}