.orderFormatedDate {
    right: 0px;
    position: absolute;
    font-size: 12px;
}

.righAligned {
    right: 0px;
    position: absolute
}

.SelectFieldTextRight {
    background-color: #ffffff;
    margin-top: 1px;
    margin-bottom: 1px;
    max-height: 2.5em;
    flex-grow: 1;
    max-width: 220px;
}

.deliveryZoneSelector {
    top: -10px;
    width: 100% !important;
}