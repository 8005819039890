
.StatsTable {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1 0.2;
}

.tablesFaca {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15%;
    grid-template-areas: ". .";
}

.tablesEnseigne {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-template-areas: ". . . .";
}

.wrapper_stats {
    margin-right: 20px; 
}

.StatsTableLab{
    display: flex;
    flex-direction: column;
}

.TableFomat {
    margin: 20px 0 0 0;
}

.StatsTableWrapper {
    margin-top: 25px;
}


.Stats_analysis {
    display: flex;
    flex-direction: row;
}

.Stats_table_titre {
    text-align: center;
    width: 100%;
}

.TablesBorder-All {
    border: 1px solid black;
}

.TablesBorder-Top{
    border-top: 1px solid black;
}

.TablesBorder-Right{
    border-right: 1px solid black;
}

.TablesBorder-Bottom{
    border-bottom: 1px solid black;
}

.TablesBorder-Left{
    border-left: 1px solid black;
}

.DeviseTable {
    width: 40%;
}
.ColumnBold{
    font-weight: bold;
}
.minus {
    color: red;
}
.plus{
    color: green;
}

.AnalysisTitleColored {
    background-color: lightgray;
}
.AnalysisTitle {
    padding: 5px 0;
    margin: 0;
    width: 100%;
    color: black;
    font-weight: bold;
}

.TablesSubTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    font-weight: bold;
}

.TablesTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    color: white;
    background-color: darkgrey;
    font-weight: bold;
}

.TablesTitle p {
    padding: 0 10px;
    margin: 0;
}

.TablesSubTitle p {
    padding: 5px 0;
    margin: 0;
}

.TableWrapperSize {
    width: 100%;
}